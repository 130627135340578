import { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
//navbar con link a due sezioni della homepage, "Il progetto" e "I dati", e un bottone per fare il login
class Layout extends Component {
  render() {
    return <>
      <Header />
      <main>
        {this.props.children}
      </main>
    </>

  }
}

export default Layout;
