import { Component } from "react";
import Home from "../Home";
import Statistics from "../Statistics";
import { Container } from "react-bootstrap";
import Footer from "../Footer";

class MainPage extends Component {
  render() {
    return (
      <Container className="page-container" fluid style={{ padding: 0 }}>
        <Home />
        <Statistics />
        <Footer />
      </Container>
    );
  }
}

export default MainPage;
