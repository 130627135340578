import { Form, Row, Col } from "react-bootstrap";

function FormDateField({
  id,
  defaultValue,
  disabled,
  editable,
  label,
  onFieldChange,
}) {
  let newOnFieldChange = (event) => {
    // change the event value from string to boolean!
    let value = event.target.value;
    onFieldChange(event.target.id, value);
  };
  return (
    <Form.Group controlId={id}>
      <Row>
        {label && (
          <Col>
            <Form.Label>{label}</Form.Label>
          </Col>
        )}
        <Col>
          <Form.Control
            type="date"
            defaultValue={disabled ? null : defaultValue}
            onChange={newOnFieldChange}
            disabled={disabled || !editable}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default FormDateField;
