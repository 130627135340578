import { Component } from "react";
import { Pie } from "react-chartjs-2";

class PieChart extends Component {
  render() {
    const zero = Object.values(this.props.data).every((value) => value === 0);

    const data = {
      labels: zero ? [] : Object.keys(this.props.data),
      datasets: [
        {
          data: zero ? [1] : Object.values(this.props.data),
          backgroundColor: zero
            ? "rgb(200, 200, 200)"
            : this.props.backgroundColor,
          borderWidth: zero ? 0 : 2,
        },
      ],
    };

    const options = {
      plugins: {
        title: {
          display: this.props.title ? true : false,
          text: this.props.title,
          font: {
            size: 20,
          },
        },
        tooltips: {
          enabled: zero ? false : true,
        },
        legend: {
          display: zero ? false : true,
        },
      },
    };

    return (
      <div className="pie-chart">
        <Pie data={data} options={options} />
      </div>
    );
  }
}

export default PieChart;
