// utilizzo di firebase per l'interfaccia al DB

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc, getDocs, setDoc, deleteDoc, doc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Patient from "../models/Patient";


const firebaseConfig = {
  apiKey: "AIzaSyA7TPfuhthY8AwKMCr9o48YsaBqC98L-EA",
  authDomain: "viteinceppatedb.firebaseapp.com",
  projectId: "viteinceppatedb",
  storageBucket: "viteinceppatedb.appspot.com",
  messagingSenderId: "966312681684",
  appId: "1:966312681684:web:697f0cc0d900a77c0adb41",
  measurementId: "G-L4EDY85XBY"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


// -----------------------PRIVATE API

async function addData(db, collectionName, dataObject) {
  try {
    const docRef = await addDoc(collection(db, collectionName), dataObject);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
  return null;
}


async function deleteData(db, collectionName, docName) {
  await deleteDoc(doc(db, collectionName, docName));
}

async function updateData(db, collectionName, docName, dataObject) {
  await setDoc(doc(db, collectionName, docName), dataObject);
}





// -----------------PUBLIC API


// login with email and password, and return the user object. 
export async function login(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    return user;
  } catch (error) {
    throw "login error";
  }
}

// logout
export async function logout() {
  await auth.signOut();
}

//on auth state change
export function onAuthStateChanged(callback) {
  auth.onAuthStateChanged(callback);
}


export async function getPatients() {
  const patients = [];
  const querySnapshot = await getDocs(collection(db, "Patients"));
  querySnapshot.forEach((doc) => {
    const patient = Patient.fromJSON(doc.data());
    patient.id = doc.id;
    patients.push(patient);
  });
  return patients;
}

export async function addPatient(patient) {
  let id = await addData(db, "Patients", patient.toJSON());
  patient.id = id;
}

export async function addPatients(patients) {
  for (let i = 0; i < patients.length; i++) {
    await addPatient(patients[i]);
  }
}

export async function updatePatient(patient) {
  await updateData(db, "Patients", patient.id, patient.toJSON());
}

export async function deletePatient(patientID) {
  await deleteData(db, "Patients", patientID);
}


