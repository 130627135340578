import { Component } from "react";
import PatientsList from "../PatientsList";
import { Container, Row, Col } from "react-bootstrap";
import { getStrings } from "../../utils/LanguageUtils.js";

class AdminPage extends Component {
  render() {
    return (
      <Container fluid className="page-container">
        <Row>
          <h1 className="title">{getStrings().PATIENTS_LIST}</h1>
        </Row>
        <Row>
          <Col sm={2} />
          <Col>
            <PatientsList />
          </Col>
          <Col sm={2} />
        </Row>
      </Container>
    );
  }
}

export default AdminPage;
