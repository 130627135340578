// selettore di lungua ( select/combobox con i nomi delle lingue )

import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { getStrings, setLanguage, getLanguage } from '../utils/LanguageUtils';
import languageNames from '../language/language_names.json';

function LanguageSelector() {
    let onLanguageSelected = (value) => {
        setLanguage(value);
        window.location.reload();
    };
    
    return (
        <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic" className='language-selector'>
            {languageNames[getLanguage()]}
        </Dropdown.Toggle>
    
        <Dropdown.Menu>
            <Dropdown.Item  onClick={()=>onLanguageSelected("it")}>{languageNames.it}</Dropdown.Item>
            <Dropdown.Item  onClick={()=> onLanguageSelected("en")}>{languageNames.en}</Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
    );
}

export default LanguageSelector;
