import React from 'react';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { getStrings } from '../utils/LanguageUtils';

function Footer() {
  return (

    <Navbar bg="dark" variant="dark" className="mt-auto footer" expand="lg">
      <Container>
        <Row>
          <Col md="auto">
            <Navbar.Text>
              {getStrings().COPYRIGHT} &copy; 2023
            </Navbar.Text>
          </Col>
          <Col md="auto">
            <Navbar.Text>
              {getStrings().CREDITS}
            </Navbar.Text>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default Footer;