import Patient from "../models/Patient.js";
import * as API from "../network/ViteInceppateAPI.js";
import { getStrings } from "./LanguageUtils.js";

// importazione ed esportazione di dati da e verso il database basato su file csv

const SEPARATOR = ",";


export async function exportPatientsCSV() {
    let csvText = await _getPatientsCSV();
    let fileName = "patients_" + Date.now().toString() + ".csv";
   // start download the .csv file
    let element = document.createElement("a");
    element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(csvText)
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}


export async function importPatientsFromCSV(){
    // get the file by file chooser
    let fileChooser = document.createElement("input");
    fileChooser.setAttribute("type", "file");
    fileChooser.setAttribute("accept", ".csv");
    fileChooser.style.display = "none";
    document.body.appendChild(fileChooser);
    fileChooser.click();
    await new Promise((resolve) => {
        fileChooser.onchange = resolve;
    }
    );
    let file = fileChooser.files[0];
    document.body.removeChild(fileChooser);
    // read the file
    let reader = new FileReader();
    await new Promise((resolve) => {
        reader.onload = resolve;
        reader.readAsText(file);
    }
    );
    let csv = reader.result;
    // import the patients
    await _importPatientsCSV(csv);
}


async function _getPatientsCSV() {
    let patients = await API.getPatients();
    if (patients.length === 0) {
        return "";
    }
    let csvLines = [];

    // create the headers
    let header = "";
    let keys = Object.keys(patients[0]);
    // removes from the keys "id" and "lastEdit"
    keys = keys.filter((key) => key !== "id" && key !== "lastEdit");
    for (let key of keys) {
        header += key + SEPARATOR;
    }
    header = header.slice(0, -1);
    csvLines.push(header);

    // create the rows
    for (let patient of patients) {
        let row = "";
        for (let key of keys) {
            let value = patient[key];
            // if the value is a string with a comma, add the double quotes
            if ((value instanceof String || typeof value === "string") && value.includes(",")) {
                value = '"' + value + '"';
            }
            row += value + SEPARATOR;
        }
        row = row.slice(0, -1);
        csvLines.push(row);
    }

    return csvLines.join("\n");
}


/** Read the data from the csv, and load the patients in the DB */
async function _importPatientsCSV(csv) {
    let lines = csv.split("\n");
    let patients = [];
    let header = lines[0].split(SEPARATOR);
    for (let i = 1; i < lines.length; i++) {
        let line = lines[i];
        // lines can contain commas inside double quotes
        // lines values can contains space also without double quotes
        // so we split the line only by commas not inside double quotes, space separed names are taken as one value even if they aren't wrapped in double quotes
        let values = line.match(/(".*?"|[^",\s]+(?:\s+[^",\s]+)*)/g);
        if (!values){
            continue;
        }
        let patient = {};
        for (let j = 0; j < values.length; j++) {
            let key = header[j];
            let value = values[j];
            if(value == "null"){
                value = null;
            } else if (value === "true") {
                value = true;
            } else if (value === "false") {
                value = false;
            } else if (!isNaN(value)) {
                value = Number(value);
            }
            if(key !== ""){
                // if the value is a string with double quotes, remove them
                if (value instanceof String || typeof value === "string") {
                    if (value.startsWith('"') && value.endsWith('"')) {
                        value = value.slice(1, -1);
                    }
                }
                patient[key] = value;
            }
        }
        if (Object.keys(patient).length === 0) { // if the patient is empty, skip it (e.g. an empty line)
            continue;
        }
        patients.push(Patient.fromJSON(patient));
    }
    try{
        await API.addPatients(patients);
        alert(getStrings().PATIENTS_IMPORT_SUCCESS);
    }catch(error){
        alert(getStrings().PATIENTS_IMPORT_ERROR);
        console.log(error);
    }
}


