import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Layout from "./components/pages/Layout";
import MainPage from "./components/pages/MainPage";
import LoginPage from "./components/pages/LoginPage";
import AdminPage from "./components/pages/AdminPage";
import * as API from "./network/ViteInceppateAPI.js";
import { AuthProvider, makePrivate } from "./network/auth/auth";

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <BrowserRouter>
        <AuthProvider>
          <Layout>
            <Routes>
              {/*<Route index element={<MainPage />} />*/}
              <Route path="/" element={<MainPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="admin" element={makePrivate(<AdminPage />)} />
              <Route path="*" element={<MainPage />} />
            </Routes>
          </Layout>
        </AuthProvider>
      </BrowserRouter>
    );
  }
}

export default App;
