import { Form, Row, Col } from "react-bootstrap";
import { getStrings } from "../utils/LanguageUtils";

const NULL_STR = JSON.stringify(null);

function FormDropdownField({
  id,
  defaultValue,
  values,
  editable,
  disabled,
  label,
  onFieldChange,
}) {
  let newOnFieldChange = (event) => {
    // change the event value from string to boolean!
    let value =
      event.target.value === "true" ||
      event.target.value === "false" ||
      event.target.value === "null"
        ? JSON.parse(event.target.value)
        : event.target.value;
    
    if (disabled) // if disabled, set value to undefined so that it is not sent to the unsaved changes
      value = undefined;

    onFieldChange(event.target.id, value);
  };
  values = [...values, { label: getStrings().UNKNOWN, value: "null" }];

  defaultValue = defaultValue === null ? NULL_STR : defaultValue;

  return (
    <Form.Group controlId={id}>
      <Row>
        {label && (
          <Col>
            <Form.Label>{label}</Form.Label>
          </Col>
        )}
        <Col>
          {
          <Form.Control
            as="select"
            defaultValue={defaultValue}
            onChange={newOnFieldChange}
            disabled={disabled || !editable}
          >
            {values.map((value) => {
              // se il valore ha una proprietà "label" usa quella come label, altrimenti usa il valore stesso
              // se il valore ha una proprietà "value" usa quella come valore, altrimenti usa il valore stesso
              let optionValue = value.hasOwnProperty("value")
                ? value.value
                : value;
              let optionLabel = value.hasOwnProperty("label")
                ? value.label
                : value;
              return (
                <option key={optionValue} value={optionValue}>
                  {optionLabel}
                </option>
              );
            })}
          </Form.Control>
          }
        </Col>
      </Row>
    </Form.Group>
  );
}

export default FormDropdownField;
