import { Component } from "react";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import Patient from "../models/Patient.js";
import WarningPopUp from "./WarningPopUp.js";
import FormTextField from "./FormTextField.js";
import FormDropdownField from "./FormDropdownField.js";
import FormDateField from "./FormDateField.js";
import FormCheckboxField from "./FormCheckboxField.js";
import { getStrings } from "../utils/LanguageUtils.js";
import { AuthContext } from "../network/auth/auth.js";

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

class PatientCard extends Component {
  #unsavedChanges;
  #patient;

  constructor(props) {
    super(props);
    this.#unsavedChanges = {};
    this.#patient = props.patient;
    this.state = {
      editable: props.editable,
      popupShow: false,
    };
  }


  resetUnsavedChanges() {
    this.#unsavedChanges = {};
    this.props.unsavedChangesMap.delete(this.#patient);
  }


  handleEdit = () => {
    this.#unsavedChanges = {};
    this.setState({ editable: true });
  };

  handleSave = async (user) => {
    let toBeAdded = false;

    if (!user) {
      alert(getStrings().LOGIN_REQUIRED);
      return;
    }

    // se l'utente non ha nome e id, ma è stato inserito un nome, allor ail paziente è nuovo, quindi aggiungilo al database
    if (!this.#patient.name && !this.#patient.id && this.#unsavedChanges.name) {
      toBeAdded = true;
    }

    //se il paziente è nuovo e non è stato inserito il nome, non salvare
    if (this.#patient.name === null && !this.#unsavedChanges.name) {
      alert(getStrings().PATIENT_NAME_REQUIRED);
      return;
    }

    // controlla che i campi modificati siano validi
    const { name, pasungDistance, postPasungDistance, pasungDuration } = this.#unsavedChanges;
    if (name === null || name === "") {
      alert(getStrings().PATIENT_NAME_REQUIRED);
      return;
    }

    if ( pasungDistance !== undefined && postPasungDistance !== undefined && pasungDistance !== null && postPasungDistance !== null &&
      (
      !(isNumber(pasungDistance)) ||
      !(isNumber(postPasungDistance)) 
      )
    ) {
      alert(getStrings().DISTANCE_MUST_BE_NUMBER);
      console.log(pasungDistance, postPasungDistance, );
      return;
    }

    if (
      (parseInt(pasungDistance) <= 0) ||
      (parseInt(postPasungDistance) <= 0)
    ) {
      alert(getStrings().DISTANCE_GREATER_THAN_ZERO);
      return;
    }


    if (
      pasungDuration !== undefined &&
      pasungDuration !== null &&
      (
        !isNumber(pasungDuration) || parseInt(pasungDuration) <= 0
      ) 
    ) {
      alert(getStrings().DURATION_MUST_BE_NUMBER);
    }


    //sostituisci le stringhe vuote con null
    for (let key in this.#unsavedChanges) {
      if (this.#unsavedChanges[key] === "") {
        this.#unsavedChanges[key] = null;
      }
    }

    this.#unsavedChanges["lastEdit"] = {
      user: user.email,
      timestamp: new Date(),
    };

    // aggiorna i valori del paziente
    Object.assign(this.#patient, this.#unsavedChanges);
    try {
      if (toBeAdded) {
        await this.props.addPatient(this.#patient);
      } else {
        await this.props.updatePatient(this.#patient);
      }
      this.resetUnsavedChanges();
      this.setState({ editable: false });
    } catch (error) {
      console.log(error);
      alert(getStrings().PATIENT_UPDATE_ERROR);
    }
  };

  handleCancel = () => {
    // se il paziente è nuovo eliminalo dalla lista dei pazienti
    if (this.#patient.name === null) {
      this.props.deleteLocalPatient(this.#patient);
    }

    //imposta i valori dei campi al valore corrente del paziente
    for (let key in this.#unsavedChanges) {
      // get the form field by id
      let formField = document.getElementById(key); // set the field value to the original value
      if (formField) {
        formField.value = this.#patient[key];
      }
    }

    this.resetUnsavedChanges();
    this.setState({ editable: false });
  };

  handleFieldChange = (id, value) => {
    this.#unsavedChanges[id] = value;
    this.props.unsavedChangesMap.set(this.#patient, this.#unsavedChanges);
    // per far che venga chiesto di salvare le modifiche prima di uscire dalla pagina
    this.forceUpdate();
  };

  handleDelete = () => {
    this.handlePopupClose();
    this.props.deleteRemotePatient(this.#patient);
  };

  handlePopupClose = () => {
    this.setState({ popupShow: false });
  };
  handlePopupShow = () => {
    this.setState({ popupShow: true });
  };

  getEducationLevelOptions = () => {
    const options = [];
    for (let key in Patient.educationLevelOptions) {
      options.push({
        label: getStrings()[key],
        value: Patient.educationLevelOptions[key],
      });
    }
    return options;
  };

  getCurrentPatientProperty(propertyName) {
    return this.#unsavedChanges.hasOwnProperty(propertyName)
      ? this.#unsavedChanges[propertyName]
      : this.#patient[propertyName];
  }

  render() {
    const { editable, popupShow } = this.state;
    const currentPasung = this.getCurrentPatientProperty("pasung");
    const currentMedicationsAccess =
      this.getCurrentPatientProperty("medicationsAccess");
    const currentMedicationsMonitoringPossible = this.getCurrentPatientProperty(
      "medicationsMonitoringPossible"
    );
    return (
      <AuthContext.Consumer>
        {(context) => {
          const user = context.user;
          return (
            <Card>
              <Card.Header>
                <Form>
                  {editable ? (
                    <Col>
                      <Form.Label>{getStrings().NAME}</Form.Label>
                      <FormTextField
                        id="name"
                        defaultValue={this.#patient.name}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                      />
                    </Col>
                  ) : (
                    <Col>
                      <Card.Title>{this.#patient.name}</Card.Title>
                    </Col>
                  )}
                </Form>
              </Card.Header>

              <Card.Body>
                <Form>
                  <Row>
                    <Col>
                      <FormDropdownField
                        id="sex"
                        defaultValue={this.#patient.sex}
                        onFieldChange={this.handleFieldChange}
                        values={["F", "M"]}
                        editable={editable}
                        label={getStrings().SEX}
                      />
                      <FormDateField
                        id="birthdate"
                        defaultValue={this.#patient.birthdate}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().BIRTHDATE}
                      />
                      <FormTextField
                        id="birthplace"
                        defaultValue={this.#patient.birthplace}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().BIRTHPLACE}
                      />
                      <FormTextField
                        id="profession"
                        defaultValue={this.#patient.profession}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().WORKING_CONDITION}
                      />
                      <FormDropdownField
                        id="educationLevel"
                        defaultValue={this.#patient.educationLevel}
                        onFieldChange={this.handleFieldChange}
                        values={this.getEducationLevelOptions()}
                        editable={editable}
                        label={getStrings().EDUCATION_LEVEL}
                      />
                      <FormDropdownField
                        id="economicCondition"
                        defaultValue={this.#patient.economicCondition}
                        onFieldChange={this.handleFieldChange}
                        values={Patient.economicConditionOptions}
                        editable={editable}
                        label={getStrings().ECONOMIC_CONDITION}
                      />
                      <FormCheckboxField
                        id="internetAccess"
                        defaultValue={this.#patient.internetAccess}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().INTERNET_ACCESS}
                      />
                    </Col>

                    <Col>
                      <FormCheckboxField
                        id="pasung"
                        defaultValue={this.#patient.pasung}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().PASUNG}
                      />
                      <FormTextField
                        id="pasungDuration"
                        defaultValue={this.#patient.pasungDuration}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().PASUNG_DURATION}
                        numeric={true}
                      />
                      <FormDateField
                        id="pasungEndDate"
                        defaultValue={this.#patient.pasungEndDate}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={currentPasung || currentPasung === null}
                        label={getStrings().PASUNG_END_DATE}
                        numeric={true}
                      />
                      <FormTextField
                        id="pasungDistance"
                        defaultValue={this.#patient.pasungDistance}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={!currentPasung}
                        label={getStrings().PASUNG_DISTANCE}
                        numeric={true}
                      />
                      <FormTextField
                        id="postPasungDistance"
                        defaultValue={this.#patient.postPasungDistance}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={currentPasung || currentPasung === null}
                        label={getStrings().POST_PASUNG_DISTANCE}
                        numeric={true}
                      />
                      <FormCheckboxField
                        id="reachableByCar"
                        defaultValue={this.#patient.reachableByCar}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().REACHABLE_BY_CAR}
                      />
                      <FormCheckboxField
                        id="reachableByMotorbike"
                        defaultValue={this.#patient.reachableByMotorbike}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().REACHABLE_BY_MOTORBIKE}
                      />
                      <FormCheckboxField
                        id="reachableOnFoot"
                        defaultValue={this.#patient.reachableOnFoot}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().REACHABLE_ON_FOOT}
                      />
                    </Col>

                    <Col>
                      <FormCheckboxField
                        id="careRequested"
                        defaultValue={this.#patient.careRequested}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().CARE_REQUESTED}
                      />
                      <FormCheckboxField
                        id="medicationsAccess"
                        defaultValue={this.#patient.medicationsAccess}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().MEDICATIONS_ACCESS}
                      />
                      <FormCheckboxField
                        id="medicationsAccepted"
                        defaultValue={this.#patient.medicationsAccepted}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={currentPasung}
                        label={getStrings().MEDICATIONS_ACCEPTED}
                      />
                      <FormCheckboxField
                        id="medicationsMonitoringPossible"
                        defaultValue={
                          this.#patient.medicationsMonitoringPossible
                        }
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={!currentMedicationsAccess || currentPasung}
                        label={getStrings().MEDICATIONS_MONITORING_POSSIBLE}
                      />
                      <FormCheckboxField
                        id="medicationsMonitoring"
                        defaultValue={this.#patient.medicationsMonitoring}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={!currentMedicationsMonitoringPossible}
                        label={getStrings().MEDICATIONS_MONITORING}
                      />
                      <FormCheckboxField
                        id="pastCSM"
                        defaultValue={this.#patient.pastCSM}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().PAST_CSM}
                      />
                      <FormCheckboxField
                        id="presentCSM"
                        defaultValue={this.#patient.presentCSM}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={currentPasung}
                        label={getStrings().PRESENT_CSM}
                      />
                      <FormCheckboxField
                        id="italianPsychiatryRequested"
                        defaultValue={this.#patient.italianPsychiatryRequested}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        label={getStrings().ITALIAN_PSYCHIATRY_REQUESTED}
                      />
                      <FormCheckboxField
                        id="psychophysicalImprovement"
                        defaultValue={this.#patient.psychophysicalImprovement}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={currentPasung}
                        label={getStrings().PSYCHOPHYSICAL_IMPROVEMENT}
                      />
                      <FormCheckboxField
                        id="socialImprovement"
                        defaultValue={this.#patient.socialImprovement}
                        onFieldChange={this.handleFieldChange}
                        editable={editable}
                        disabled={currentPasung}
                        label={getStrings().SOCIAL_IMPROVEMENT}
                      />
                    </Col>
                  </Row>

                  <Row>
                    {editable ? (
                      <>
                        <Col className="col-1">
                          <Button
                            variant="success"
                            onClick={() => this.handleSave(user)}
                          >
                            {getStrings().SAVE}
                          </Button>
                        </Col>
                        <Col className="col-1">
                          <Button variant="danger" onClick={this.handleCancel}>
                            {getStrings().CANCEL}
                          </Button>
                        </Col>
                        <Col className="col-10" />
                      </>
                    ) : (
                      <>
                        <Col className="col-1">
                          <Button variant="primary" onClick={this.handleEdit}>
                            {getStrings().EDIT}
                          </Button>
                        </Col>
                        <Col className="col-1">
                          <Button
                            variant="danger"
                            onClick={this.handlePopupShow}
                          >
                            {getStrings().DELETE}
                          </Button>
                        </Col>
                        <Col>
                          <WarningPopUp
                            text={getStrings().DELETE_PATIENT_WARNING}
                            show={popupShow}
                            onHide={this.handlePopupClose}
                            onCancel={this.handlePopupClose}
                            onConfirm={this.handleDelete}
                          />
                        </Col>
                      </>
                    )}
                    {/** mostra utente e data di ultima modifica */}
                    <Col className="last-edit align-self-end">
                      {this.#patient.lastEdit && (
                        <p>
                          {getStrings().LAST_EDIT} {this.#patient.lastEdit.user}{" "}
                          {getStrings().ON}{" "}
                          {/*PRINT THE TIMESTAMP LIKE: DAY-MONTH-YEAR*/}
                          {this.#patient.lastEdit.timestamp.toLocaleDateString()}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default PatientCard;
