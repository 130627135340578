import { Form, Row, Col } from "react-bootstrap";

function FormTextField({
  id,
  defaultValue,
  editable,
  disabled,
  label,
  onFieldChange,
  numeric,
}) {

  let newOnFieldChange = (event) => {
    // change the event value from string to boolean!
    let value = event.target.value;
    if(numeric) {
      value = parseInt(value);
    }
    if(disabled) // if disabled, set value to undefined so that it is not sent to the unsaved changes
      value = undefined;
    onFieldChange(event.target.id, value);
  };

    let handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    };

  return (
    <Form.Group controlId={id}>
      <Row>
        {label && (
          <Col >
            <Form.Label>{label}</Form.Label>
          </Col>
        )}

        <Col>
          <Form.Control
            type={numeric? "number": "text"}
            defaultValue={disabled ? null : defaultValue}
            onChange={newOnFieldChange}
            disabled={disabled || !editable}
            onKeyDown={handleKeyDown}
            inputMode={numeric? "numeric": "text"}
            pattern={numeric? "[0-9]*" : undefined}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default FormTextField;
