import { useContext, createContext } from "react";
import { useState, useEffect } from "react";
import { onAuthStateChanged } from "../ViteInceppateAPI";
import { Navigate } from "react-router-dom";

export const AuthContext = createContext({user: null});


export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(setCurrentUser);
    }, []);

    

  return (
    <AuthContext.Provider value={{ user: currentUser }}>
      {children}
    </AuthContext.Provider>
  
  );
}


export function makePrivate(element) {
  return (
    <AuthContext.Consumer>
      {(context) => {
        return context.user ? element : <Navigate to="/login" />;
      }}
    </AuthContext.Consumer>
  );
}

