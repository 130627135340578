import { Component } from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

class BarChart extends Component {
  static VERTICAL = "x";
  static HORIZONTAL = "y";
  render() {
    const orientation = this.props.orientation;
    const data = {
      labels: Object.keys(this.props.data),
      // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
      datasets: [
        {
          data: Object.values(this.props.data),
          backgroundColor: this.props.backgroundColor,
          borderWidth: 2,
          borderRadius: 20,
        },
      ],
    };
    const options = {
      indexAxis: orientation || BarChart.VERTICAL,
      responsive: true,
      plugins: {
        title: {
          display: this.props.title ? true : false,
          text: this.props.title,
          font: {
            size: 20,
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        ...(orientation === BarChart.VERTICAL && {
          y: {
            max: this.props.max,
          },
        }),
        ...(orientation === BarChart.HORIZONTAL && {
          x: {
            max: this.props.max,
          },
        }),
      },
    };
    return (
      <div className="bar-chart">
        <Bar data={data} options={options} />
      </div>
    );
  }
}

export default BarChart;
