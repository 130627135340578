import { Component } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { getStrings } from "../utils/LanguageUtils";
import { AuthContext } from "../network/auth/auth";
import { logout, onAuthStateChanged } from "../network/ViteInceppateAPI.js";
import { withRouter } from "../utils/withRouter.js";
import LanguageSelector from "./LanguageSelector.js";

class Header extends Component {
  constructor(props) {
    super(props);
    onAuthStateChanged((user) => {
      // this function is called when the user logs in or logs out to update the header
      this.forceUpdate();
    });
  }

  render() {
    return (
      <AuthContext.Consumer>
        {(context) => {
          const user = context.user;
          return (
            <>
              <Navbar
                className="header bg-body-tertiary"
                bg="success"
                expand="lg"
                fixed="top"
              >
                <Navbar.Brand>
                  <img src="/logo.png" alt="*" width="40" height="40" />{" "}
                  {getStrings().APPLICATION_TITLE + " "}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="mr-auto" id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link
                      className="header-link"
                      as={HashLink}
                      to={"/#project"}
                    >
                      {getStrings().NAVBAR_PROJECT}
                    </Nav.Link>
                    <Nav.Link
                      className="header-link"
                      as={HashLink}
                      to={"/#statistics"}
                    >
                      {getStrings().NAVBAR_DATA}
                    </Nav.Link>
                    {user && (
                      <Nav.Link
                        className="header-link"
                        as={NavLink}
                        to={"/admin"}
                      >
                        Admin
                      </Nav.Link>
                    )}
                  </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse
                  className="justify-content-end"
                  id="basic-navbar-nav"
                >
                  <Nav className="ml-md-auto justify-content-end">
                    <LanguageSelector />
                    {user && (
                      <>
                        <Navbar.Text>{user.email}</Navbar.Text>
                        <Nav.Link
                          className="header-link"
                          onClick={() => {
                            logout();
                            this.forceUpdate();
                            this.props.router.navigate("/login");
                          }}
                        >
                          Logout
                        </Nav.Link>
                      </>
                    )}
                    {!user && (
                      <Nav.Link
                        className="header-link"
                        as={NavLink}
                        to="/login"
                      >
                        {getStrings().LOGIN}
                      </Nav.Link>
                    )}
                    <svg
                      className="bi bi-people-circle"
                      width="30"
                      height="30"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 008 15a6.987 6.987 0 005.468-2.63z" />
                      <path
                        fillRule="evenodd"
                        d="M8 9a3 3 0 100-6 3 3 0 000 6z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M8 1a7 7 0 100 14A7 7 0 008 1zM0 8a8 8 0 1116 0A8 8 0 010 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              <Outlet />
            </>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default withRouter(Header);
