import { Component } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { login } from "../../network/ViteInceppateAPI.js";
import { getStrings } from "../../utils/LanguageUtils.js";
import { withRouter } from "../../utils/withRouter.js";
import { Title } from "chart.js";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // stampa email e password
    // login
    try {
      const user = await login(this.state.email, this.state.password);
      alert(getStrings().LOGIN_SUCCESS, user);
      // redirect to the root page
      this.props.router.navigate("/admin");
    } catch (error) {
      alert(getStrings().LOGIN_FAILED);
    }
  };

  render() {
    //render login form with validation
    return (
      <Container className="page-container">
          <h1 className="title pt-3">{getStrings().LOGIN}</h1>
          <Form onSubmit={this.handleSubmit}>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={4} />
              <Col sm={4}>
                <Form.Group controlId="formEmail">
                  <Form.Label>{getStrings().EMAIL}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={getStrings().ENTER_EMAIL}
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={4} />
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={4} />
              <Col>
                <Form.Group controlId="formPassword">
                  <Form.Label>{getStrings().PASSWORD}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={getStrings().ENTER_PASSWORD}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={4} />
            </Row>
            <Row>
              <Col sm={4} />
              <Col>
                <Button variant="primary" type="submit">
                  Login
                </Button>
              </Col>
              <Col sm={4} />
            </Row>
          </Form>
      </Container>
    );
  }
}

export default withRouter(LoginPage);
