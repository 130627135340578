import { Component } from "react";
import FormDropdownField from "./FormDropdownField";
import { getStrings } from "../utils/LanguageUtils.js";

const VALUES = [
  { label: getStrings().YES, value: "true" },
  { label: getStrings().NO, value: "false" },
];

function FormCheckboxField({
  id,
  defaultValue,
  editable,
  disabled,
  label,
  onFieldChange,
}) {
  return (
    <FormDropdownField
      id={id}
      defaultValue={defaultValue}
      onFieldChange={onFieldChange}
      values={VALUES}
      editable={editable}
      disabled={disabled}
      label={label}
    />
  );
}

export default FormCheckboxField;
