class Patient {
  static SexOptions = ["F", "M"];

  static economicConditionOptions = [1, 2, 3, 4, 5];

  static educationLevelOptions = {
    NONE: 0,
    PRIMARY_SCHOOL: 1,
    SECONDARY_SCHOOL: 2,
    HIGH_SCHOOL: 3,
    BACHELOR_DEGREE: 4,
    MASTER_DEGREE: 5,
    PHD: 6,
  };

  static fromJSON(json) {
    const patient = new Patient();
    for (let key in json) {
      patient[key] = json[key];
      if (key === "lastEdit") {
        // convert timestamp to date
        if (json[key]) {
         patient[key] = {
           user: json[key].user,
           timestamp: new Date(json[key].timestamp.seconds * 1000),
         };
        }else{
          patient[key] = null;
        }
        
      }
    }
    return patient;
  }

  toJSON() {
    const json = {};
    for (let key in this) {
      let exportedKey = key;
      json[key] = this[exportedKey];
    }

    return json;
  }

  constructor() {
    this.id = null;
    this.name = null;
    this.sex = null;
    this.birthdate = null;
    this.birthplace = null;
    this.profession = null;
    this.educationLevel = null;
    this.economicCondition = null;
    this.internetAccess = null;
    this.pasung = null;
    this.pasungEndDate = null;
    this.pasungDuration = null;
    this.pasungDistance = null;
    this.postPasungDistance = null;
    this.reachableByCar = null;
    this.reachableByMotorbike = null;
    this.reachableOnFoot = null;
    this.careRequested = null;
    this.medicationsAccess = null;
    this.medicationsAccepted = null;
    this.medicationsMonitoringPossible = null;
    this.medicationsMonitoring = null;
    this.pastCSM = null;
    this.presentCSM = null;
    this.italianPsychiatryRequested = null;
    this.psychophysicalImprovement = null;
    this.socialImprovement = null;
    this.lastEdit = null;
  }
}

export default Patient;
