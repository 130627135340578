import { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PatientCard from "./PatientCard";
import Patient from "../models/Patient.js";
import { getStrings } from "../utils/LanguageUtils.js";
import { BsPersonAdd } from "react-icons/bs";
import { withRouter } from "../utils/withRouter.js";
import * as API from "../network/ViteInceppateAPI.js";
import * as DBImporterExporter from "../utils/db_import_export.js";

class PatientsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patients: [],
    };
    this.unsavedChangesMap = new Map(); // mappa che associa ad un paziente non ancora salvato le sue modifiche
    // quando ci sono delle entry in questa mappa, viene mostrato un alert all'utente se prova a cambiare pagina
    // if there are unsaved changes if the user tries to change page the browser will show an alert
    window.onbeforeunload = () => {
      if (this.unsavedChangesMap.size > 0) {
        return true;
      }
    }
  }

  componentWillUnmount() {
    // se ci sono modifiche non salvate, chiede all'utente se vuole davvero cambiare pagina, altrimenti rimane su questa
    if (this.unsavedChangesMap.size > 0) {
      window.alert(getStrings().UNSAVED_CHANGES_ALERT);
    }

  }

  async componentDidMount() {
    const patients = await API.getPatients();
    this.setState({ patients });
    this.sortPatients();
  }

  sortPatients = () => {
    //ordina i pazienti per nome
    this.setState((prevState) => ({
      patients: prevState.patients.sort((a, b) => {
        if (!a.name || !b.name) return 0;
        return a.name.localeCompare(b.name);
      }),
    }));
  };

  scrollToBottom = () => {
    const end = document.getElementById("end");
    end.scrollIntoView({ behavior: "smooth" });
  };

  componentDidUpdate(prevProps, prevState) {
    const { patients } = this.state;
    const prevPatients = prevState.patients;

    if (patients.length > prevPatients.length && prevPatients.length > 0) {
      this.scrollToBottom();
    }
  }

  deleteRemotePatient = async (remotePatient) => {
    //elimina il paziente con l'id passato
    const id = remotePatient.id;
    try {
      await API.deletePatient(id);
      alert(getStrings().PATIENT_DELETE_SUCCESS);
      this.setState((prevState) => ({
        patients: prevState.patients.filter((patient) => patient.id !== id),
      }));
    } catch (error) {
      console.log(error);
      alert(getStrings().PATIENT_DELETE_ERROR);
    }
  };

  // utilizzata per annullare modifiche a un paziente non ancora salvato
  deleteLocalPatient = async (localPatient) => {
    //elimina il paziente con l'id passato
    try {
      this.setState((prevState) => ({
        patients: prevState.patients.filter((patient) => patient !== localPatient),
      }));
      alert(getStrings().PATIENT_CANCEL_SUCCESS);
    } catch (error) {
      alert(getStrings().PATIENT_CANCEL_ERROR);
    }
  };

  addRemotePatient = async (patient) => {
    try {
      await API.addPatient(patient);
      alert(getStrings().PATIENT_ADD_SUCCESS);
      this.forceUpdate();
    } catch (error) {
      alert(getStrings().PATIENT_ADD_ERROR);
      console.log(error);
    }
  };

  addLocalPatient = async () => {
    try {
      let newPatient = new Patient();
      this.setState((prevState) => ({
        patients: [...prevState.patients, newPatient],
      }));
    } catch (error) {
      alert(getStrings().PATIENT_ADD_ERROR);
      console.log(error);
    }
  };

  async updatePatient(patient) {
    try {
      await API.updatePatient(patient);
      alert(getStrings().PATIENT_UPDATE_SUCCESS);
    } catch (error) {
      console.log(error);
      alert(getStrings().PATIENT_UPDATE_ERROR);
    }
  }

  importPatientsFromCSV = async () => {
    await DBImporterExporter.importPatientsFromCSV();
    this.setState({ patients: await API.getPatients() });
    this.sortPatients();
  };

  exportPatientsToCSV = async () => {
    await DBImporterExporter.exportPatientsCSV();
  };

  render() {
    const { patients } = this.state;
    return (
      <Container className="patientsList" fluid>
        <Row>
          <Col>
            <Button
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                zIndex: 9999,
              }}
              variant="primary"
              size="lg"
              floating="true"
              onClick={this.addLocalPatient}
            >
              <BsPersonAdd />
            </Button>
          </Col>
        </Row>
        <Row>
          {/**add space between the 2 buttons */}
          <Col className="col-1">
            <Button
              variant="secondary"
              onClick={this.importPatientsFromCSV}
            >
              {getStrings().IMPORT_PATIENTS_FROM_CSV}
            </Button>
          </Col>
          <Col className="col-1">
            <Button
              variant="success"
              onClick={this.exportPatientsToCSV}
            >
              {getStrings().EXPORT_PATIENTS_TO_CSV}
            </Button>
          </Col>
        </Row>
        {patients.length >= 0 && patients.map((patient) => (
          <Row
            style={{
              paddingBottom: "25px",
              paddingTop: "25px",
            }}
          >
            <Col>
              <PatientCard
                key={patient.id}
                patient={patient}
                editable={patient.name === null ? true : false}
                deleteRemotePatient={this.deleteRemotePatient}
                deleteLocalPatient={this.deleteLocalPatient}
                updatePatient={this.updatePatient}
                addPatient={this.addRemotePatient}
                unsavedChangesMap={this.unsavedChangesMap}
              />
            </Col>
          </Row>
        ))}
        {patients.length === 0 && (
          <Row>
            <Col>
              <h3>{getStrings().EMPTY_PATIENTS_LIST}</h3>
            </Col>
          </Row>
        )}
        <div id="end"></div>
      </Container>
    );
  }
}

export default withRouter(PatientsList);
