import React, { Component } from "react";
import { Container, Image, Carousel } from "react-bootstrap";
import { getStrings } from "../utils/LanguageUtils.js";
import Thumbnail from "../images/thumbnail.png";
import CarouselImage1 from "../images/img1.jpg";
import CarouselImage2 from "../images/img2.jpg";
import CarouselImage3 from "../images/img3.jpg";
import CarouselImage4 from "../images/img4.jpg";
import CarouselImage5 from "../images/img5.jpg";
import CarouselImage6 from "../images/img6.jpg";


//sezione della main page in cui si presenta il progetto

class Home extends Component {
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <Image src={Thumbnail} style={{ width: "100%" }} />
        <h1
          className="title"
          style={{
            fontSize: "5vw",
            position: "absolute",
            top: "5%",
            right: "20%",
            left: 0,
            textShadow: "2px 2px 4px #000000",
          }}
        >
          {getStrings().PROJECT_NAME}
          <h2
            className="subtitle"
            style={{
              fontSize: "3vw",
            }}
          >
            {getStrings().INDONESIA}
          </h2>
        </h1>

        <h1 id="project" className="title">{getStrings().PROJECT_TITLE}</h1>

        <Container className="text-paragraph">
          <p>{getStrings().PROJECT_DESCRIPTION}</p>
          <ul>
            <li>{getStrings().FATHER_LUIGI_GALVANI}</li>
            <li>{getStrings().DR_FATIMAH_KANZAA}</li>
            <li>{getStrings().DR_VINCENZO_VILLARI}</li>
            <li>{getStrings().DR_CLAUDIA_AMORUSO}</li>
            <li>{getStrings().VOLUNTEERS}</li>
          </ul>
        </Container>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={CarouselImage1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={CarouselImage2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={CarouselImage3}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={CarouselImage4}
              alt="Fourth slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={CarouselImage5}
              alt="Fifth slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={CarouselImage6}
              alt="Sixth slide"
            />
          </Carousel.Item>
        </Carousel>
      </Container>
    );
  }
}

export default Home;
