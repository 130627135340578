import { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { getStrings } from "../utils/LanguageUtils.js";

class WarningPopUp extends Component {
  render() {
    const { text, show, onHide, onCancel, onConfirm } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{getStrings().WARNING}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            {getStrings().CANCEL}
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            {getStrings().CONFIRM}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WarningPopUp;
