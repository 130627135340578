import strings_it from "../language/strings_it.json";
import strings_en from "../language/strings_en.json";

//js locale

const supportedLanguages = ["it", "en"];
const defaultStrings = strings_it;
const defaultLanguage = "it";

let currentLanguage;

setLanguage(window.localStorage.getItem("language"));

function validateLanguage(language) {
  if (supportedLanguages.includes(language)) {
    return language;
  }
  return defaultLanguage;
}

export function setLanguage(language) {
  currentLanguage = validateLanguage(language);
  window.localStorage.setItem("language", language);
}

export function getLanguage() {
  return currentLanguage;
}

export function getStrings() {
  if (getLanguage() === "it") {
    return strings_it;
  }
  else if (getLanguage() === "en") {
    return strings_en;
  }
  return defaultStrings;
}
