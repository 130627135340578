// QUERY PER I PAZIENTI

import Patient from "../models/Patient.js";

//totale pazienti
export function totalPatients(patients) {
  return patients.length;
}

//totale minorenni
export function totalMinors(patients) {
  let minors = 0;
  const today = new Date();
  const adultDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  return patients.filter((patient) => new Date(patient.birthdate) > adultDate)
    .length;
}

//totale maggiorenni
export function totalAdults(patients) {
  const today = new Date();
  const adultDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  return patients.filter((patient) => new Date(patient.birthdate) < adultDate)
    .length;
}

//totale pazienti maschi
export function totalMales(patients) {
  // return the total of male patients
  return patients.filter((patient) => patient.sex === "M").length;
}

//totale pazienti femmine
export function totalFemales(patients) {
  return patients.filter((patient) => patient.sex === "F").length;
}

//totale pazienti di cui si conosce accesso ad iternet e totale pazienti che hanno accesso a internet
export function totalInternetAccess(patients) {
  return patients.filter((patient) => patient.internetAccess === true).length;
}

//totale pazienti con pasung
export function totalPasung(patients) {
  return patients.filter((patient) => patient.pasung === true).length;
}

//totale pazienti raggiungibili in auto
export function totalReachableByCar(patients) {
  return patients.filter((patient) => patient.reachableByCar === true).length;
}

//totale pazienti raggiungibili in moto
export function totalReachableByMotorbike(patients) {
  return patients.filter((patient) => patient.reachableByMotorbike === true)
    .length;
}

//totale pazienti raggiungibili a piedi
export function totalReachableByFoot(patients) {
  return patients.filter((patient) => patient.reachableOnFoot === true).length;
}

//statistiche condizione economica per ogni livello
export function economicCondition(patients) {
  const data = {};
  const economicConditionOptions = Patient.economicConditionOptions;
  for (const option of economicConditionOptions) {
    data[option] = 0;
  }
  for (let i = 0; i < patients.length; i++) {
    const economicCondition = patients[i].economicCondition;
    data[economicCondition]++;
  }
  return data;
}

//statistiche livello educazione per ogni livello
export function educationLevels(patients) {
  const data = {};
  const educationLevelOptions = Object.values(Patient.educationLevelOptions);
  for (const option of educationLevelOptions) {
    data[option] = 0;
  }
  for (let i = 0; i < patients.length; i++) {
    const educationLevel = patients[i].educationLevel;
    data[educationLevel]++;
  }
  return data;
}

export function averagePasungDuration(patients) {
  let total = 0;
  let count = 0;
  for (let i = 0; i < patients.length; i++) {
    const patient = patients[i];
    if (patient.pasungDuration) {
      total += patient.pasungDuration;
      count++;
    }
  }
  return total / count;
}

//media distanza pasung
export function averagePasungDistance(patients) {
  let total = 0;
  let count = 0;
  for (let i = 0; i < patients.length; i++) {
    const patient = patients[i];
    if (patient.pasungDistance) {
      total += patient.pasungDistance;
      count++;
    }
  }
  return total / count;
}

//media distanza post pasung
export function averagePostPasungDistance(patients) {
  let total = 0;
  let count = 0;
  for (let i = 0; i < patients.length; i++) {
    const patient = patients[i];
    if (patient.postPasungDistance) {
      total += patient.postPasungDistance;
      count++;
    }
  }
  return total / count;
}

//totale accesso a farmaci
export function totalMedicationsAccess(patients) {
  return patients.filter((patient) => patient.medicationsAccess === true)
    .length;
}

//totale accettazione farmaci
export function totalMedicationsAccepted(patients) {
  return patients.filter((patient) => patient.medicationsAccepted === true)
    .length;
}

//totale possibilità di monitoraggio farmaci
export function totalMedicationsMonitoringPossible(patients) {
  return patients.filter(
    (patient) => patient.medicationsMonitoringPossible === true
  ).length;
}

//totale monitoraggio farmaci
export function totalMedicationsMonitoring(patients) {
  return patients.filter((patient) => patient.medicationsMonitoring === true)
    .length;
}

//totale richiedenti cure
export function totalCareRequested(patients) {
  return patients.filter((patient) => patient.careRequested === true).length;
}

//totale richieste di psichiatria italiana
export function totalItalianPsychiatryRequested(patients) {
  return patients.filter(
    (patient) => patient.italianPsychiatryRequested === true
  ).length;
}

//totale miglioramenti psicofisici
export function totalPsychophysicalImprovement(patients) {
  return patients.filter(
    (patient) => patient.psychophysicalImprovement === true
  ).length;
}

//totale miglioramenti sociali
export function totalSocialImprovement(patients) {
  return patients.filter((patient) => patient.socialImprovement === true)
    .length;
}

//totale che non hanno ne past ne present CSM
export function totalNoCSM(patients) {
  return patients.filter(
    (patient) => patient.presentCSM === false && patient.pastCSM === false
  ).length;
}

//totale che hanno solo CSM passato
export function totalPastCSM(patients) {
  return patients.filter(
    (patient) => patient.pastCSM === true && patient.presentCSM === false
  ).length;
}

//totale solo CSM presente
export function totalPresentCSM(patients) {
  return patients.filter(
    (patient) => patient.presentCSM === true && patient.pastCSM === false
  ).length;
}

//totale CSM passato e presente
export function totalPastAndPresentCSM(patients) {
  return patients.filter(
    (patient) => patient.presentCSM === true && patient.pastCSM === true
  ).length;
}
