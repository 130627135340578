import { Component } from "react";
import { ProgressBar } from "react-bootstrap";

class ProgressBarChart extends Component {
  render() {
    return (
      <div>
        <h3 className="progress-bar-chart-label">{`(${this.props.now.toFixed(2)}%)`}</h3>
        <ProgressBar className="progress-bar-chart"  now={this.props.now} variant="info"/>
      </div>
    );
  }
}

export default ProgressBarChart;
