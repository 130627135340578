import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Patient from "../models/Patient.js";
import BarChart from "./charts/BarChart.js";
import PieChart from "./charts/PieChart.js";
import ProgressBarChart from "./charts/ProgressBarChart.js";
import { getStrings } from "../utils/LanguageUtils.js";
import * as API from "../network/ViteInceppateAPI.js";
import * as pq from "../utils/patientsQueries.js";

//five pie chart colors
const PIE_CHART_COLORS = [
  "rgb(135, 206, 250)",
  "rgb(255, 153, 204)",
  "rgb(255, 255, 153)",
  "rgb(175, 250, 175)",
  "rgb(221, 160, 221)",
];

/*const PIE_CHART_COLORS = [
  "rgb(152, 251, 152)",
  "rgb(100, 179, 146)",
  "rgb(50, 205, 50)",
  "rgb(34, 139, 34)",
  "rgb(0, 100, 0)",
];*/

const BAR_CHART_COLORS = [
  "rgb(255, 153, 204)",
  "rgb(175, 250, 175)",
  "rgb(255, 255, 153)",
];

const EDUCATION_LEVEL_COLORS = [
  "rgb(255, 179, 179)",
  "rgb(236, 193, 144)",
  "rgb(217, 207, 110)",
  "rgb(198, 221, 77)",
  "rgb(179, 235, 43)",
  "rgb(160, 250, 0)",
  "rgb(128, 255, 0)",
];

const ECONOMIC_CONDITION_COLORS = [
  "rgb(255, 179, 179)",
  "rgb(228, 203, 153)",
  "rgb(201, 227, 128)",
  "rgb(174, 250, 102)",
  "rgb(128, 255, 128)",
];

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patients: [],
    };
    this.loadPatients();
  }

  async loadPatients() {
    const patients = await API.getPatients();
    this.setState({ patients });
  }

  render() {
    const patients = this.state.patients;

    const total = pq.totalPatients(patients);

    const economicConditions = pq.economicCondition(patients);

    const educationLevels = {};
    for (let key in Patient.educationLevelOptions) {
      let value = Patient.educationLevelOptions[key];
      educationLevels[getStrings()[key]] = pq.educationLevels(patients)[value];
    }

    const internetAccess = (pq.totalInternetAccess(patients) / total) * 100;

    const pasung = (pq.totalPasung(patients) / total) * 100;

    const pasungDuration = Math.round(pq.averagePasungDuration(patients));

    const distances = {
      [getStrings().PASUNG_DISTANCE]: Math.round(
        pq.averagePasungDistance(patients)
      ),
      [getStrings().POST_PASUNG_DISTANCE]: Math.round(
        pq.averagePostPasungDistance(patients)
      ),
    };

    const reachability = {
      [getStrings().REACHABLE_BY_CAR]: pq.totalReachableByCar(patients),
      [getStrings().REACHABLE_BY_MOTORBIKE]:
        pq.totalReachableByMotorbike(patients),
      [getStrings().REACHABLE_ON_FOOT]: pq.totalReachableByFoot(patients),
    };

    const medicationsAccess =
      (pq.totalMedicationsAccess(patients) / total) * 100;

    const careRequested = (pq.totalCareRequested(patients) / total) * 100;

    //totale di pazienti che accettano farmaci su pazienti che non hanno il pasung
    console.log(
      "total: " + total,
      "pasung: " + pq.totalPasung(patients),
      "accepted: " + pq.totalMedicationsAccepted(patients)
    );

    const medicationsAccepted = {
      [getStrings().YES]: pq.totalMedicationsAccepted(patients),
      [getStrings().NO]:
        total -
        pq.totalPasung(patients) -
        pq.totalMedicationsAccepted(patients),
    };

    //totale di pazienti per cui è possibile il monitoraggio farmaci su pazienti che accettano farmaci
    const medicationsMonitoringPossible = {
      [getStrings().YES]: pq.totalMedicationsMonitoringPossible(patients),
      [getStrings().NO]:
        pq.totalMedicationsAccepted(patients) -
        pq.totalMedicationsMonitoringPossible(patients),
    };

    //totale di pazienti per cui vengono monitorati i farmaci su pazienti per cui è possibile il monitoraggio farmaci
    const medicationsMonitoring = {
      [getStrings().YES]: pq.totalMedicationsMonitoring(patients),
      [getStrings().NO]:
        pq.totalMedicationsMonitoringPossible(patients) -
        pq.totalMedicationsMonitoring(patients),
    };

    const CSM = {
      [getStrings().NEVER]: pq.totalNoCSM(patients),
      [getStrings().PAST]: pq.totalPastCSM(patients),
      [getStrings().PRESENT]: pq.totalPresentCSM(patients),
      [getStrings().PAST_AND_PRESENT]: pq.totalPastAndPresentCSM(patients),
    };

    const intalianPsychiatryRequested =
      (pq.totalItalianPsychiatryRequested(patients) / total) * 100;

    const improvement = {
      [getStrings().PSYCHOPHYSICAL_IMPROVEMENT]:
        pq.totalPsychophysicalImprovement(patients),
      [getStrings().SOCIAL_IMPROVEMENT]: pq.totalSocialImprovement(patients),
    };

    return (
      <Container>
        <h1 id="statistics" className="title">
          {getStrings().STATISTICS_TITLE}
        </h1>
        <Container className="text-paragraph">
          <p className="text-paragraph">
            {getStrings().STATISTICS_INTRODUCTION}
          </p>
        </Container>
        <Row className="justify-content-center m-5">
          <Col md="auto">
            <hr/>
            <h1 className="total-patients">{getStrings().TOTAL_PATIENTS + ": " + total}</h1>
            <hr/>
          </Col>
        </Row>
        <Row className="justify-content-center m-6">
        <h1 className="subsection-title">{getStrings().DEMOGRAPHIC_INFO}</h1>
        <Card className="statistics-card">
          <Card.Body>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">{getStrings().SEX}</h3>
                <PieChart
                  backgroundColor={PIE_CHART_COLORS}
                  data={{
                    [getStrings().MALES]: pq.totalMales(patients),
                    [getStrings().FEMALES]: pq.totalFemales(patients),
                  }}
                />
              </Col>
              <Col align="center">
                <h3 className="chart-title">{getStrings().AGE}</h3>
                <PieChart
                  backgroundColor={PIE_CHART_COLORS}
                  data={{
                    [getStrings().MINORS]: pq.totalMinors(patients),
                    [getStrings().ADULTS]: pq.totalAdults(patients),
                  }}
                />
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">{getStrings().EDUCATION_LEVEL}</h3>
                <BarChart
                  backgroundColor={EDUCATION_LEVEL_COLORS}
                  data={educationLevels}
                  max={total}
                />
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">
                  {getStrings().ECONOMIC_CONDITION}
                </h3>
                <BarChart
                  backgroundColor={ECONOMIC_CONDITION_COLORS}
                  data={economicConditions}
                  max={total}
                />
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">{getStrings().INTERNET_ACCESS}</h3>
                <ProgressBarChart now={internetAccess} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        </Row>

        <Row className="justify-content-center m-5">
        <h1 className="subsection-title">{getStrings().COERCIVE_STATUS_INFO}</h1>
        <Card className="statistics-card" fluid>
          <Card.Body>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">{getStrings().PASUNG}</h3>
                <ProgressBarChart now={pasung} />
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">
                  {getStrings().AVERAGE_PASUNG_DURATION}
                </h3>
                <h1>{pasungDuration + " " + getStrings().YEARS}</h1>
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">{getStrings().AVARAGE_DISTANCE}</h3>
                <BarChart
                  orientation={BarChart.HORIZONTAL}
                  backgroundColor={BAR_CHART_COLORS}
                  data={distances}
                />
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">{getStrings().REACHABILITY}</h3>
                <BarChart
                  backgroundColor={BAR_CHART_COLORS}
                  data={reachability}
                  max={total}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        </Row>


        <Row className="justify-content-center m-5">
        <h1 className="subsection-title">{getStrings().TREATMENT_INFO}</h1>
        <Card className="statistics-card">
          <Card.Body>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">{getStrings().CARE_REQUESTED}</h3>
                <ProgressBarChart now={careRequested} />
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">
                  {getStrings().MEDICATIONS_ACCESS}
                </h3>
                <ProgressBarChart now={medicationsAccess} />
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">
                  {getStrings().MEDICATIONS_ACCEPTED}
                </h3>
                <PieChart
                  backgroundColor={PIE_CHART_COLORS}
                  data={medicationsAccepted}
                />
              </Col>
              <Col align="center">
                <h3 className="chart-title">
                  {getStrings().MEDICATIONS_MONITORING_POSSIBLE}
                </h3>
                <PieChart
                  backgroundColor={PIE_CHART_COLORS}
                  data={medicationsMonitoringPossible}
                />
              </Col>
              <Col align="center">
                <h3 className="chart-title">
                  {getStrings().MEDICATIONS_MONITORING}
                </h3>
                <PieChart
                  backgroundColor={PIE_CHART_COLORS}
                  data={medicationsMonitoring}
                />
              </Col>
            </Row>
            <Row className="statistics-card-row">
              <Col />
              <Col align="center" md="6">
                <h3 className="chart-title">{getStrings().CSM}</h3>
                <PieChart backgroundColor={PIE_CHART_COLORS} data={CSM} />
              </Col>
              <Col />
            </Row>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">
                  {getStrings().ITALIAN_PSYCHIATRY_REQUESTED}
                </h3>
                <ProgressBarChart now={intalianPsychiatryRequested} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        </Row>
        <Row className="justify-content-center m-5">
        <h1 className="subsection-title">{getStrings().IMPROVEMENT_INFO}</h1>
        <Card className="statistics-card">
          <Card.Body>
            <Row className="statistics-card-row">
              <Col align="center">
                <h3 className="chart-title">{getStrings().IMPROVEMENT_INFO}</h3>
                <BarChart
                  backgroundColor={BAR_CHART_COLORS}
                  data={improvement}
                  max={total - pasung}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        </Row>
      </Container>
    );
  }
}

export default Statistics;
